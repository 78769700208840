
@use '@angular/material' as mat;
@include mat.core();

$quansic-palette: (
  50 : #e3e3e3,
  100 : #b8b8b8,
  200 : #898989,
  300 : #595959,
  400 : #363636,
  500 : #212121,
  600 : #101010,
  700 : #0d0d0d,
  800 : #0a0a0a,
  900 : #050505,
  A100 : #ff4e4e,
  A200 : #801e21,
  A400 : #5c1517,
  A700 : #4b080a,
  contrast: (
      50 : #000000,
      100 : #000000, 
      200 : #000000,
      300 : #ffffff,
      400 : #ffffff,
      500 : #ffffff,
      600 : #ffffff,
      700 : #ffffff,
      800 : #ffffff,
      900 : #ffffff,
      A100 : #000000,
      A200 : #ffffff,
      A400 : #ffffff,
      A700 : #ffffff,
  )
);

$primary: mat.define-palette($quansic-palette);
$accent:  mat.define-palette($quansic-palette, A200, A100, A400);

/* Y  ou can add global styles to this file, and also import other style files */
@media only screen and (max-width: 768px) {
  h1 {
    font-size: 29px;
  }
}

@media only screen and (min-width: 768px) {
  h1 {
    font-size: 39px;
  }
}
// h1 {
//   font-family: "Poppins";
//   letter-spacing: 3px;
//   font-weight: 900;
//   color: #515A67;
// }

$typography: mat.define-typography-config(
  $font-family: 'Montserrat, sans-serif',
  $headline-5: mat.define-typography-level(39px, 39px, 900, 'Poppins'),
  $subtitle-1: mat.define-typography-level(39px, 39px, 900, 'Poppins'),
  $subtitle-2: mat.define-typography-level(16px, 16px, 300, 'Roboto'),
  $body-2: mat.define-typography-level(14px, 24px, 400, 'Montserrat', 0.0179em),
  $body-1: mat.define-typography-level(14px, 20px, 400, 'Montserrat', 0.0179em)
);


$theme: mat.define-dark-theme((
  color: (
    primary: $primary,
    accent: $accent
  ),
  typography: $typography,
  density: 0,
));

@include mat.core-theme($theme);
// Include all theme styles for the components.
@include mat.all-component-themes($theme);
@include mat.typography-hierarchy($theme);


body {
  color: #141414;
  background-color: #F5F7FB;
}


h1 {
  color: #515a67;
}
p.h3 {
  font-size: 1.3rem;
}

strong {
  font-weight: 500;
}

.btn-primary {
  border-color: #6B7789;
  border-color: #6B7789;
}

.bg-primary {
	background-color: #6B7789!important /*rgba(0,0,0,0)!important;*/
}

.registry .btn {
	width: 80px;
}

.btn.btn-primary {
  background-color: #6B7789;
  border-color: #6B7789;
}

.col-form-label {
  font-size: 17px;
}


html, body { height: 100%; }
// body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }

.content {
  margin-top: 3rem;
  padding: 0 0.5rem;
}

.mat-mdc-tab-group.tab-vertical {
  flex-direction: row !important;
  
  .mat-mdc-tab-labels {
    flex-direction: column !important;
  }
  .mat-mdc-tab-body-wrapper {
    flex-grow: 1;
  }
}

.mdc-tab__text-label {
  color: #203a45 !important;
  font-size: 1.17rem;
  font-family: Montserrat;
  font-weight: 500;
  line-height: 1.2;
}
.mdc-tab--active {
  background-color: #fff !important;
  font-weight: bold !important;
}
.mdc-tab__ripple, .mdc-tab-indicator{
  display: none !important;
}
.mat-mdc-tab-body {
  padding: 20px;
}
.mat-mdc-tab-body,
.mat-mdc-tab-body-content  {
  background-color: #fff !important;
}

.mat-tabs {
  background-color: #fff !important;
  color: #203a45;
}
.mdc-tab {
  justify-content: flex-start !important;
}